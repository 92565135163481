import React from "react";
import clsx from "clsx";
import {
	Box,
	Button,
	Container,
	Typography,
	Grid,
	withStyles
} from "@material-ui/core";
import Layout from "gatsby-theme-hypersite/src/layout";
import { Content } from "../components";

const ContactPage = ({ classes, hero, ...props }) => {
	const { root, container, content, imageWrapper, image } = classes;
	return (
		<Layout {...props}>
			<Box className={clsx(classes.root)}>
				<Container maxWidth="lg">
					<Grid container spacing={6}>
						<Grid item xs={12} md={6}>
							<Typography variant="h4" gutterBottom>
								Contact us civilcourtdata@lsc.gov
							</Typography>
							<Button variant="contained" href="mailto:civilcourtdata@lsc.gov">
								Contact us now
							</Button>
						</Grid>
						<Grid item xs={12} md={6}>
							<Typography variant="h4" gutterBottom>
								Sign up to be the first to know when we release new data and
								analyses
							</Typography>
							<Button
								variant="contained"
								href="https://app.smartsheet.com/b/form/47fa11ccd2644addb5cf0c9ab07bedf5"
								target="_blank"
							>
								Sign up now
							</Button>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</Layout>
	);
};

ContactPage.defaultProps = {
	// hero: {
	// 	title: "ContactPage Us",
	// 	lede: "",
	// 	image: "",
	// }
};

ContactPage.propTypes = {};

export default withStyles(theme => ({
	root: {
		padding: theme.spacing(16, 0),
		background: theme.palette.background.paperSecondary,
		"& .MuiGrid-item": {
			// padding: theme.spacing(8),
			textAlign: "center"
		},
		"& .MuiGrid-container": {
			position: "relative",
			"&::before": {
				[theme.breakpoints.up("md")]: {
					content: "''",
					width: 1,
					height: "100%",
					position: "absolute",
					top: 0,
					left: "50%",
					backgroundColor: theme.palette.background.dark
				}
			}
		}
	}
}))(ContactPage);
